import { replacePropsWithPreview } from '../../utils';
import { trackCheckPriceAndAvailability } from '@/lib/tracking/tracking';
import type { ObjectData } from '@/domain';

export const ProfileButton = ({ objectData, isPreview = false }: { objectData: ObjectData; isPreview?: boolean }) => {
    const { isAdmin, owner, bookingEnabled } = objectData;

    // Should never show profile button for private/import ads, but show for admin if calendar is disabled
    if (!isAdmin || bookingEnabled) {
        return null;
    }

    return (
        <div className="my-16" data-testid="profileButton">
            <a
                className="button button--primary w-full max-w-full"
                {...replacePropsWithPreview(
                    {
                        href: owner.deeplink ? owner.deeplink : (owner.url ?? undefined),
                        onClick: () => trackCheckPriceAndAvailability({ objectData: objectData }),
                    },
                    isPreview,
                )}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="seeAvailability">
                Se tilgjengelighet
            </a>
        </div>
    );
};
