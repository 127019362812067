import { useEffect } from 'react';
import { thousandSeparated } from 'shared/util/formatting';
import { SpinnerIcon } from 'shared/icons/SpinnerIcon';
import { Alert } from '@fabric-ds/react';
import { usePriceCalendar } from '@/hooks/usePriceCalendar';
import { PriceDetail } from './PriceDetail';
import { useObject } from '@/hooks/useObject';

import cn from 'classnames';

export interface PriceOverviewProps {
    adId: number;
    startDate: Date;
    endDate: Date;
    numberOfGuests: number;
    setPrice?: (priceTotal: string) => void;
    className?: string;
}

export const PriceOverview = ({ adId, startDate, endDate, numberOfGuests, setPrice, className }: PriceOverviewProps) => {
    const norwegianAddress = useObject().location.countryCode === 'NO';
    const { priceCalendar, error, isLoading } = usePriceCalendar(adId.toString(), startDate, endDate, numberOfGuests);
    useEffect(() => {
        if (priceCalendar && setPrice && !norwegianAddress) {
            setPrice(`${thousandSeparated(priceCalendar.priceNotIncludingServiceFee)} ${priceCalendar.currency}`);
        }
    }, [priceCalendar]);

    if (isLoading) return <SpinnerIcon />;
    if (error)
        return (
            <Alert type="negative" show>
                Beklager, noe gikk galt ved henting av pris.
            </Alert>
        );
    if (!priceCalendar)
        return (
            <Alert type="info" show>
                Ta kontakt med utleier for pris.
            </Alert>
        );

    const titleWithNumberOfNights = `Pris for ${priceCalendar.pricesOfDateRange?.length} ${
        (priceCalendar.pricesOfDateRange?.length ?? 0) > 1 ? 'netter' : 'natt'
    }`;

    return (
        <div className={cn(className, 'text-14')}>
            <h3 className="text-14">Pris</h3>
            <PriceDetail
                title={titleWithNumberOfNights}
                value={priceCalendar.priceOfStayWithExtraCost}
                norwegianAddress={norwegianAddress}
            />
            <PriceDetail
                title="Rabatt for lengre opphold"
                value={priceCalendar.discount}
                isNegateValue={true}
                norwegianAddress={norwegianAddress}
            />
            <PriceDetail title="Rengjøringsgebyr" value={priceCalendar.cleaningFee} norwegianAddress={norwegianAddress} />
            {norwegianAddress && (
                <PriceDetail title="FINN.no tjenestegebyr" value={priceCalendar.serviceFee} extraPriceInformation={serviceFee} />
            )}
            <PriceDetail
                title="Totalpris"
                value={norwegianAddress ? priceCalendar.priceIncludingServiceFee : priceCalendar.priceNotIncludingServiceFee}
                norwegianAddress={norwegianAddress}
                currency={norwegianAddress ? undefined : priceCalendar.currency}
            />
            {!norwegianAddress && priceCalendar.deposit && (
                <div className="my-8 text-left text-gray-500 text-12">Depositum kommer i tillegg</div>
            )}
        </div>
    );
};

const serviceFee = {
    description: 'Tjenestegebyret gjør at vi kan fortsette å vedlikeholde og utvikle tjenestene våre. ',
    link: {
        text: 'Les mer om gebyret.',
        url: 'https://hjelpesenter.finn.no/hc/no/articles/15892543816466-Velkommen-til-nye-Feriehjem-og-Hytter#FINNs-tjenestegebyr',
    },
};
